/* eslint-disable no-mixed-spaces-and-tabs */
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { change, stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import {
	CHANGE_CAR,
	CREATE_CAR,
	CREATE_TRAILER,
	GET_CARS,
	GET_REQUEST_INFO,
	GET_TONNAGES,
	GET_TRAILERS_LIST,
	setCarsAction,
	setDriversAction,
	setIsCarChangingAction,
	setIsCarSavingAction,
	setIsCarsLoadingAction,
	setIsRequestInfoLoadingAction,
	setIsTonnagesLoadingAction,
	setIsTrailerSavingAction,
	setIsTrailersListLoadingAction,
	setRequestInfoAction,
	setTonnagesAction,
	setTrailersAction,
	setTrailersListAction,
} from './actions';
import {
	ADD_CAR_FORM_NAME,
	ADD_CAR_FORM_FIELDS,
	ADD_TRAILER_FORM_FIELDS,
	FORM_FIELDS,
	FORM_NAME,
	REQUEST_ERROR_MESSAGE,
} from './constants';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import { getRequestInfoRequest } from '../../api/reqs-requests';
import {
	getAddCarForCreateValuesSelector,
	getAddCarFormIsInvalidSelector,
	getAddTrailerForCreateValuesSelector,
	getAddTrailerFormIsInvalidSelector,
	getCarsOptionsSelector,
	getDataForSaveSelector,
	getFormIsInvalidSelector,
	getIsChangeCarSelector,
	getTrailersListSelector,
	getTrailersOptionsSelector,
} from './selectors';
import {
	createCarRequest,
	getReqsCarsListRequest,
	getTonnagesDictionaryRequest,
	getTrailersListRequest,
	saveNewTrailerRequest,
	updateMyReqWithPayloadRequest,
} from '../../api/requests';
import { getRawPaginationSelector, getReqsSelector } from '../reqs-list/selectors';
import { setPaginationAction, setReqsAction } from '../reqs-list/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* changeCarSaga({ payload: { redirect, id, searchQuery } }) {
	try {
		yield put(setIsCarChangingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForRequest = yield select(getDataForSaveSelector());

		const isChangeCar = yield select(getIsChangeCarSelector());

		const { data, message, toast, errors } = yield call(
			updateMyReqWithPayloadRequest,
			id,
			isChangeCar ? '/changeCar' : '/setCar',
			dataForRequest,
		);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link
							? {
									link: `${toast?.link}${
										removeModalQuery(searchQuery) ? `&${removeModalQuery(searchQuery)}` : ''
									}`,
							  }
							: {}),
					}),
				);
			}

			const reqs = yield select(getReqsSelector());

			let newReqs = [];

			if (data.routes.includes(window.location.pathname)) {
				const updatedReq = {
					...data,
					link: `${window.location.pathname}?${searchQuery ? `${searchQuery}&` : ''}modal=reqs_details&id=${
						data.id
					}`,
					additionalPoints: data.points.length - 2,
					renderPoints: [data.points[0], data.points.at(-1)],
				};

				newReqs = reqs.map(req => {
					return req.id === data.id ? updatedReq : req;
				});
			} else {
				newReqs = reqs.filter(req => {
					return req.id !== data.id;
				});

				const pagination = yield select(getRawPaginationSelector());

				if (pagination) {
					yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
				}
			}

			yield put(setReqsAction(newReqs));

			redirect();
		} else {
			if (errors) {
				yield put(touch(FORM_NAME, ...FORM_FIELDS));
				yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCarChangingAction(false));
	}
}

export function* getRequestInfoSaga({ payload: id }) {
	try {
		yield put(setIsRequestInfoLoadingAction(true));

		const { data, message, toast } = yield call(getRequestInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setRequestInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRequestInfoLoadingAction(false));
	}
}

export function* getCarsSaga({ payload }) {
	try {
		yield put(setIsCarsLoadingAction(true));

		const { data, message, toast } = yield call(getReqsCarsListRequest, payload);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setDriversAction(
					data.drivers.map(driver => ({
						key: driver.id,
						value: driver.id,
						text: driver.name,
					})),
				),
			);

			yield put(
				setCarsAction(
					data.cars.map(car => ({
						key: car.id,
						value: car.id,
						text: car.number,
						description: car.name,
						disabled: !car.is_accredited,
						trailer_id: car.trailer_id,
						isAllowedTrailer: car.is_allowed_trailer,
					})),
				),
			);

			const trailers = [{ text: 'Нет', value: 'none', key: 'none' }].concat(
				data.trailers.map(trailer => ({
					key: trailer.id,
					value: trailer.id,
					text: trailer.number,
					description: trailer.name,
					disabled: !trailer.is_accredited,
				})),
			);

			yield put(setTrailersAction(trailers));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCarsLoadingAction(false));
	}
}

export function* getTrailersListSaga() {
	try {
		yield put(setIsTrailersListLoadingAction(true));
		const { data, message, toast } = yield call(getTrailersListRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setTrailersListAction([
					{ text: 'Нет', value: 'none', key: 'none' },
					...data?.map(trailer => ({
						text: trailer.name,
						value: trailer.id,
						key: `${trailer.name.trim()}-${trailer.vin}-${trailer.id}`,
					})),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailersListLoadingAction(false));
	}
}

export function* createCarSaga({ payload: { redirect, searchQuery } }) {
	try {
		yield put(setIsCarSavingAction(true));

		const isInvalid = yield select(getAddCarFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getAddCarForCreateValuesSelector());

		const { data, errors, message, toast } = yield call(createCarRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const cars = yield select(getCarsOptionsSelector());

			yield put(setCarsAction([{ key: data.id, value: data.id, text: data.name }, ...cars]));

			yield put(change(FORM_NAME, 'car_id', data.id));

			if (data.trailer?.id) {
				yield put(change(FORM_NAME, 'trailer_id', data.trailer.id));
			}

			redirect();
		} else {
			yield put(touch(FORM_NAME, ...ADD_CAR_FORM_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCarSavingAction(false));
	}
}

export function* createTrailerSaga({ payload: { redirect, searchQuery, modalToAdd } }) {
	try {
		yield put(setIsTrailerSavingAction(true));

		const isInvalid = yield select(getAddTrailerFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getAddTrailerForCreateValuesSelector());

		const { data, errors, message, toast } = yield call(saveNewTrailerRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const trailers = yield select(getTrailersListSelector());

			const mainTrailers = yield select(getTrailersOptionsSelector());

			yield put(setTrailersListAction([{ key: data.id, value: data.id, text: data.name }, ...trailers]));

			yield put(setTrailersAction([{ key: data.id, value: data.id, text: data.name }, ...mainTrailers]));

			if (modalToAdd === 'car') {
				yield put(change(ADD_CAR_FORM_NAME, 'trailer', data.id));
			}

			yield put(change(FORM_NAME, 'trailer_id', data.id));

			redirect();
		} else {
			yield put(touch(FORM_NAME, ...ADD_TRAILER_FORM_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerSavingAction(false));
	}
}

export function* getTonnagesSaga() {
	try {
		yield put(setIsTonnagesLoadingAction(true));

		const { data, message, toast } = yield call(getTonnagesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setTonnagesAction([
					{ text: 'Нет', value: 'none', key: 'none' },
					...data.map(tonnage => ({
						key: String(tonnage.id),
						value: String(tonnage.id),
						text: tonnage.name,
					})),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTonnagesLoadingAction(false));
	}
}

export default function* carChangeModalSaga() {
	yield takeEvery(CHANGE_CAR, changeCarSaga);
	yield takeEvery(GET_REQUEST_INFO, getRequestInfoSaga);
	yield takeEvery(GET_CARS, getCarsSaga);
	yield takeEvery(GET_TRAILERS_LIST, getTrailersListSaga);
	yield takeEvery(CREATE_CAR, createCarSaga);
	yield takeEvery(CREATE_TRAILER, createTrailerSaga);
	yield takeEvery(GET_TONNAGES, getTonnagesSaga);
}
